import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SiteMetaHook from '../hooks/site-meta-hook'
import CategoryHook from '../hooks/category-hook'
import PostList from '../components/post-list'
import ArticlePreview from '../components/article-preview'
import Button from '../components/button'
import FullScreenHero from '../components/full-screen-hero'
import LocalImageHook from '../hooks/local-images-hook'
import Hero from '../components/hero'
import ReamMoreButton from '../components/read-more-button'

export default ({ data }) => {
  const metaData = SiteMetaHook()
  const categoryData = CategoryHook()
  const heroImage = LocalImageHook().bodyofworks_cover.childImageSharp
  const currentCategory = ''
  const posts = data.allContentfulBlogPost.edges
  const loadPageAmount = 3
  const [postsToShow, setPostsToShow] = useState(3)
  // console.log(pageInfo);
  return (
    <Layout>
      <FullScreenHero
        title={`RYZEN HOUSE seems ok
          會呼吸的建築`}
        description={`美術館的延伸 夢想的初始
        不只世界，不只美術，而且生活。`}
        heroImage={heroImage}
        link={'#'}
      />
      <div className="wrap px-6 mx-auto py-8 md:px-20 md:pt-12 md:pb-24">
        <div className="py-10 md:py-20">
          <h1 className="uppercase font-normal text-center tracking-widest text-gray-33 text-20 md:text-36">
            Stories
          </h1>
        </div>
        <div className="grid grid-rows-1 gap-5 md:grid-rows-2 mb-12 md:mb-32 md:grid-flow-col md:gap-8 md:h-45vw">
          <div className="relative pb-full md:row-span-2 md:pb-0">
            <div className="absolute inset-0">
              <Img fluid={heroImage.fluid} className="w-full h-full" />
            </div>
            <div className="absolute px-6 py-6 flex flex-col justify-end inset-0 text-white">
              <div className="text-sm text-gray-4d mb-1 md:mb-2">時尚</div>
              <h3 className="text-17-25 mb-1 md:text-20-25 md:mb-2">
                RYZEN 聯名
              </h3>
              <p className="text-13-18 mb-2 md:text-base md:mb-4">獨特設計</p>
              <div>
                <ReamMoreButton>READ MORE</ReamMoreButton>
              </div>
            </div>
          </div>
          <div className="relative pb-full md:pb-0">
            <div className="absolute inset-0">
              <Img fluid={heroImage.fluid} className="w-full h-full" />
            </div>
            <div className="absolute px-6 py-6 flex flex-col justify-end inset-0 text-white">
              <div className="text-sm text-gray-4d mb-1">時尚</div>
              <h3 className="text-17-25 mb-1">RYZEN 聯名</h3>
              <p className="text-13-18 mb-2">獨特設計</p>
              <div>
                <ReamMoreButton>READ MORE</ReamMoreButton>
              </div>
            </div>
          </div>
          <div className="relative pb-full md:pb-0">
            <div className="absolute inset-0">
              <Img fluid={heroImage.fluid} className="w-full h-full" />
            </div>
            <div className="absolute px-6 py-6 flex flex-col justify-end inset-0 text-white">
              <div className="text-sm text-gray-4d mb-1">時尚</div>
              <h3 className="text-17-25 mb-1">RYZEN 聯名</h3>
              <p className="text-13-18 mb-2">獨特設計</p>
              <div>
                <ReamMoreButton>READ MORE</ReamMoreButton>
              </div>
            </div>
          </div>
        </div>

        <ul className="posts">
          {posts.slice(0, postsToShow).map(({ node }) => {
            return (
              <li
                key={node.slug}
                className="mb-6 last:mb-8 md:mb-16 md:last:mb-16"
              >
                <ArticlePreview article={node} />
              </li>
            )
          })}
        </ul>
        <div className="text-center">
          {/*直接連到 stories*/}
          <Link to="/stories">
            <Button className="mt-12 px-8 tracking-wider">LOAD MORE</Button>
          </Link>

          {/* 往下讀取文章*/}
          {/*{*/}
          {/*  postsToShow < posts.length ?*/}
          {/*    <Button*/}
          {/*      className="mt-12 px-8 tracking-wider cursor-pointer"*/}
          {/*      onClick={()=>{setPostsToShow(postsToShow + loadPageAmount)}}*/}
          {/*    >LOAD MORE*/}
          {/*    </Button>*/}
          {/*    :*/}
          {/*    null*/}
          {/*}*/}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexQueryBK {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      limit: 999
      skip: 0
    ) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "D MMMM, YYYY")
          heroImage {
            fluid(maxWidth: 870, maxHeight: 575, resizingBehavior: FILL) {
              ...GatsbyContentfulFluid_withWebp
            }
            fixed(
              width: 870
              height: 575
              quality: 10
              resizingBehavior: FILL
            ) {
              ...GatsbyContentfulFixed_withWebp
            }
          }
          category {
            slug
            title
            titleTw
          }
          body {
            body
            childMarkdownRemark {
              html
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
      }
    }
  }
`
